/*
	This font software is the property of Commercial Type.

	You may not modify the font software, use it on another website, or install it on a computer.

	License information is available at http://commercialtype.com/eula
	For more information please visit Commercial Type at http://commercialtype.com or email us at info[at]commercialtype.com

	Copyright (C) 2016 Schwartzco Inc.
	
*/

@font-face {
	font-family: 'YS Display';
	src: url('YS Display-Regular.eot');
	src: url('YS Display-Regular.eot?#iefix') format('embedded-opentype'),
		url('YS Display-Regular.woff2') format('woff2'),
		url('YS Display-Regular.woff') format('woff'),
		url('YS Display-Regular.ttf') format('truetype'),
		url('YS Display-Regular.svg#YSDisplay-Regular') format('svg');
	font-weight: 400;
	font-style: normal;
}
