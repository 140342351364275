$primary: #000000;
$secondary: #e9eae8;
$background: white;
$allow: #66bb6a;
$deny: #f44336;

$font: 'YS Display', Roboto, Helvetica, Arial, sans-serif;
$fontMono: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
$baseFontSize: 16px;
$baseFontColor: #222425;

$maxPageWidth: 1200px;
$defaultPadding: 1rem;

$panelBorder: 1px solid gray;
$overlayIconSize: 15vmin;
$overlayBackground: rgba(255, 255, 255, 0.8);

$cellSize: 5vmin;
$tableBorder: 1px solid gray;
$cellBackground: white;
$cellLabelBackground: #efefef;
$overlayIconSize: 15vmin;

$fleetAmountSize: 50px;
