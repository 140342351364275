/* Generated by Glyphter (http://www.glyphter.com) on  Thu May 25 2023*/
@font-face {
	font-family: 'Glyphter';
	src: url('./Glyphter.eot');
	src: url('./Glyphter.eot?#iefix') format('embedded-opentype'),
		url('./Glyphter.woff') format('woff'),
		url('./Glyphter.ttf') format('truetype'),
		url('./Glyphter.svg#Glyphter') format('svg');
	font-weight: normal;
	font-style: normal;
}
[class*='icon-']:before {
	display: inline-block;
	font-family: 'Glyphter';
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.icon-basket-shopping-solid:before {
	content: '\0041';
}
.icon-plus-solid:before {
	content: '\0042';
}
.icon-pen-to-square-regular:before {
	content: '\0043';
}
.icon-pen-to-square-solid:before {
	content: '\0044';
}
.icon-pen-solid:before {
	content: '\0045';
}
.icon-circle-xmark-solid:before {
	content: '\0046';
}
.icon-xmark-solid:before {
	content: '\0047';
}
.icon-user-solid:before {
	content: '\0048';
}
.icon-heart-regular:before {
	content: '\0049';
}
.icon-heart-solid:before {
	content: '\004a';
}
.icon-trash-can-regular:before {
	content: '\004b';
}
.icon-trash-can-solid:before {
	content: '\004c';
}
