@use 'src/sass/variables' as *;
@use 'src/sass/mixins';

.container {
  display: flex;
  gap: 1rem;
  user-select: none;
}

.winner {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
}
