@use 'src/sass/variables' as *;
@use 'src/sass/mixins';

.container {
}

.input {
	padding: 0.5rem 1rem;
	line-height: 1.2rem;
	font-size: 1.2rem;
	text-align: center;
}

.form {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.field {
	display: flex;
	flex-direction: column;
}

.label {

}
