@use 'src/sass/variables' as *;
@use 'src/sass/mixins';

.container {
	
}

.status {
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
  font-size: 1.2rem;
  z-index: 1;
}
