@use '../variables' as *;

@mixin hoverableBackground($color: $secondary) {
	&:hover {
		background-color: $color;
	}

	&:disabled {
		opacity: 0.5;
	}

	&:disabled:hover {
		background-color: $background;
		cursor: default;
	}
}

@mixin hoverableColor($color: lighten($baseFontColor, 25%)) {
	&:hover {
		color: $color;
	}

	&:disabled {
		opacity: 0.5;
	}

	&:disabled:hover {
		color: $baseFontColor;
		cursor: default;
	}
}
